<div class="row" style="margin-top: 10px">
  <ngx-spinner bgColor="rgba(255,255,255,0.2)" size="large" color="#fff" type="ball-scale-multiple"></ngx-spinner>

  <div class="col col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
    <!--Basic Info-->
    <nb-accordion>
      <nb-accordion-item expanded>
        <nb-accordion-item-header>
          <div class="container">
            <div class="row">
              <div class="col">Basic Info</div>
            </div>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class="padding"></div>
          <div class="row">
            <div class="container">
              <div class="row">
                <div class="col-12 col-md-6 mb-3">
                  <label>Branch Name</label>
                  <input class="input-style" type="text" fullWidth nbInput [(ngModel)]="BranchData.name"
                    (change)="editBaiscInfo(BranchData)" name="name" [value]="branchName"
                    placeholder="{{ branchData?.name }}" />
                </div>

                <div class="col-12 col-md-6 mb-3">
                  <label>Branch ID</label>
                  <input class="input-style" placeholder="Enter Branch ID" type="text" fullWidth nbInput
                    [value]="branchID" disabled />
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label>Domain Address</label>
                  <input class="input-style" placeholder="Enter Domain Address" type="text" fullWidth nbInput
                    [value]="branchData.domainAddress" [(ngModel)]="BranchData.domainAddress"
                    (change)="editBaiscInfo(BranchData)" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label>Business Name</label>
                  <nb-select placeholder="Select Business" fullWidth [(selected)]="BusinessID"
                    [(ngModel)]="BranchData.businessId" (selectedChange)="editBaiscInfo(BranchData)">
                    <nb-option *ngFor="let business of businesses" value="{{ business?.id }}">
                      {{ business?.name }}</nb-option>
                  </nb-select>

                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label>Primary Phone NO.</label>
                  <input class="input-style" type="text" fullWidth nbInput [value]="branchData.firstPhoneNumber" [(ngModel)]="BranchData.firstPhoneNumber"
                    (change)="editBaiscInfo(BranchData)" name="phonenumber" placeholder="Enter Phone Number" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label>Subscription</label>
                  <nb-select placeholder="Select Subscription" fullWidth [(selected)]="SubcriptionID"
                    [(ngModel)]="BranchData.subscriptionTypeId" (selectedChange)="editBaiscInfo(BranchData)">
                    <nb-option *ngFor="let subscription of subscriptionTypes" [value]="subscription.id">
                      {{ subscription.name }}</nb-option>
                  </nb-select>
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label>Country</label>
                  <nb-select placeholder="Select Country" fullWidth [(selected)]="CountryID"
                    (selectedChange)="editAddress(AddressData, 'country')" [(ngModel)]="AddressData.countryId">
                    <nb-option *ngFor="let country of countries" [value]="country.id">
                      {{ country.name }}</nb-option>
                  </nb-select>
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label>City</label>
                  <nb-select placeholder="Select City" fullWidth [(selected)]="CityID" [(ngModel)]="AddressData.cityId"
                    (selectedChange)="editAddress(AddressData, 'city')">
                    <nb-option *ngFor="let city of cities" [value]="city.id">
                      {{ city.name }}
                    </nb-option>
                  </nb-select>
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label>Location</label>
                  <nb-form-field>
                    <input type="text" class="input-style" [value]="Location" fullWidth placeholder="Pick your Location"
                      nbInput [(ngModel)]="AddressData.location" (change)="editAddress(AddressData, '')" />
                    <button nbSuffix nbButton ghost (click)="openSelectLocation()">
                      <nb-icon icon="map-outline" pack="eva"> </nb-icon>
                    </button>
                  </nb-form-field>
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label>Address</label>
                  <input class="input-style" [value]="Address" type="text" fullWidth nbInput
                    placeholder="Enter Address"  disabled />
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label>Sales Man</label>
                  <nb-select placeholder="Select Sales Man" fullWidth [(selected)]="SalesManId"
                    [(ngModel)]="BranchData.salesManId" (selectedChange)="editBaiscInfo(BranchData)">
                    <nb-option *ngFor="let salesMan of salesMen" value="{{ salesMan.id }}">{{ salesMan.salesManName }}
                    </nb-option>
                  </nb-select>
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label>Subscription Package</label>
                  <input class="input-style" [value]="branchData.currentPlan?.packageName?? 'not subscribed to any package'" type="text" fullWidth nbInput
                    placeholder="Enter Address" disabled />
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label>Package Expiration Date</label>
                  <input class="input-style" value="{{branchData.currentPlan?.expirationDate?( branchData.currentPlan?.expirationDate | date) : '-'}}" type="text" fullWidth nbInput
                    placeholder="Enter Address" disabled />
                </div>
                <div class="col-12 text-is-right">
                  <button nbButton status="success" small (click)="SaveEditBasicInfo()">
                    <nb-icon icon="save"></nb-icon>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
    <!--Basic Info-->
    <div class="padding"></div>
    <!--Prams-->
    <nb-accordion>
      <nb-accordion-item expanded>
        <nb-accordion-item-header>
          <div class="container">
            <div class="row">
              <div class="col">Params</div>
            </div>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class="row">
            <div class="container">
              <div class="padding"></div>
              <div class="row">
                <div class="col col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-xs-12">
                  No of decimal point
                  <input class="input-style" type="number" fullWidth nbInput [value]="decimalPoint"
                    [(ngModel)]="BranchParamData.decimalPoint" (change)="editBranchParam(BranchParamData)"
                    placeholder="{{ branchParams?.decimalPoint }}" />
                </div>

                <div class="col col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-xs-12">
                  Discount
                  <nb-select placeholder="Select Discount" [(selected)]="discountAllowed"
                    [(ngModel)]="BranchParamData.discountAllowed" (selectedChange)="editBranchParam(BranchParamData)"
                    fullWidth>
                    <nb-option [value]="0">false</nb-option>
                    <nb-option [value]="1">value</nb-option>
                    <nb-option [value]="2">percentage</nb-option>
                    <nb-option [value]="3">Both</nb-option>
                  </nb-select>
                </div>
              </div>
              <div class="padding"></div>
              <div class="row">
                <div class="col col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-xs-12">
                  Claim
                  <nb-select placeholder="Select Claim" [(selected)]="claimAllowed"
                    [(ngModel)]="BranchParamData.claimAllowed" (selectedChange)="editBranchParam(BranchParamData)"
                    fullWidth>
                    <nb-option [value]="0">false</nb-option>
                    <nb-option [value]="1">auto</nb-option>
                    <nb-option [value]="2">manual</nb-option>
                  </nb-select>
                </div>
                <div class="col col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-xs-12 pro-col">
                  <div class="padding"></div>
                  <nb-toggle [checked]="nfcProductSearch" [(ngModel)]="BranchParamData.nfcProductSearch"
                    (change)="editBranchParam(BranchParamData)"></nb-toggle>
                  <p class="product">NFC Product Search</p>
                </div>
              </div>
              <div class="padding"></div>
              <div class="row">
                <div class="col col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-xs-12 pro-col">
                  <nb-toggle [checked]="customerAllowed" [(ngModel)]="BranchParamData.customerAllowed"
                  (change)="editBranchParam(BranchParamData)"></nb-toggle>
                <p class="product">Customer Allowed</p>
                </div>
              </div>
              <div class="padding"></div>
              <div class="row">
                <div class="col col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-xs-12">
                  Payment
                  <!-- {{payment | json}} -->
                  <nb-select placeholder="Select Payment" [(selected)]="payment"
                    (selectedChange)="editBranchParam(BranchParamData)" [(ngModel)]="BranchParamData.payment" multiple
                    fullWidth>
                    <nb-option [value]="1">Cash</nb-option>
                    <nb-option [value]="2">Visa</nb-option>
                    <nb-option [value]="3">Bank</nb-option>
                  </nb-select>
                </div>
              </div>
              <div class="padding"></div>
              <div class="row">
                <button nbButton status="success" small (click)="SaveEditBasicInfo()" class="save">
                  <nb-icon icon="save"></nb-icon>
                  Save
                </button>
              </div>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
    <!--Prams-->
    <div class="padding"></div>
    <!--Printing-->
    <nb-accordion>
      <nb-accordion-item [expanded]="printAllowed" [disabled]="!branchParams?.printAllowed && !printAllowed">
        <nb-accordion-item-header>
          <div class="container">
            <div class="row">
              <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8">
                Printing
              </div>
              <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <nb-toggle [checked]="printAllowed" [(ngModel)]="BranchParamData.printAllowed" (change)="
                    editBranchParam(BranchParamData); SaveEditBasicInfo()
                  ">
                </nb-toggle>
              </div>
            </div>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class="row">
            <div class="container">
              <div class="padding"></div>
              <div class="row">
                <div class="col col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-xs-12">
                  Footer Message
                  <input class="input-style" type="text" fullWidth nbInput
                    placeholder="{{ branchParams?.footerMessage }}" [(ngModel)]="BranchParamData.footerMessage"
                    [value]="footerMessage" (change)="editBranchParam(BranchParamData)" />
                </div>

                <div class="col col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-xs-12">
                  Reprint
                  <nb-select placeholder="Select Reprint" fullWidth [(selected)]="rePrint"
                    [(ngModel)]="BranchParamData.rePrint" (selectedChange)="editBranchParam(BranchParamData)">
                    <nb-option [value]="false">false</nb-option>
                    <nb-option [value]="true">true</nb-option>
                  </nb-select>
                </div>
              </div>
              <div class="padding"></div>
              <div class="row">
                <div class="col col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-xs-12">
                  Merchant Copy
                  <nb-select placeholder="Select Merchant Copy" [(selected)]="merchantCopy"
                    [(ngModel)]="BranchParamData.merchantCopy" (selectedChange)="editBranchParam(BranchParamData)"
                    fullWidth>
                    <nb-option *ngFor="let merchantCopy of merchantCopies" [value]="merchantCopy.id">
                      {{ merchantCopy.status }}</nb-option>
                  </nb-select>
                </div>
              </div>
              <div class="padding"></div>
              <div class="row">
                <button nbButton status="success" small (click)="SaveEditBasicInfo()" class="save">
                  <nb-icon icon="save"></nb-icon>
                  Save
                </button>
              </div>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
    <!--Printing-->
    <div class="padding"></div>
    <!--Tax-->
    <nb-accordion>
      <nb-accordion-item [expanded]="taxAllowed" [disabled]="!branchParams?.taxAllowed && !taxAllowed">
        <nb-accordion-item-header>
          <div class="container">
            <div class="row">
              <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8">
                Tax
              </div>
              <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <nb-toggle [checked]="taxAllowed" [(ngModel)]="BranchParamData.taxAllowed" (change)="
                    editBranchParam(BranchParamData); SaveEditBasicInfo()
                  ">
                </nb-toggle>
              </div>
            </div>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class="row">
            <div class="container">
              <div class="row">
                <div class="ccol col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-xs-12">
                  Type
                  <nb-select placeholder="Select Tax Type" fullWidth [(selected)]="taxTypeId"
                    [(ngModel)]="BranchParamData.taxTypeId" (selectedChange)="editBranchParam(BranchParamData)">
                    <nb-option *ngFor="let type of taxTypes" [value]="type.id">{{ type.name }}</nb-option>
                  </nb-select>
                </div>
                <div class="col col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-xs-12">
                  Value
                  <nb-select placeholder="Select Tax Value" fullWidth [(selected)]="taxID"
                    [(ngModel)]="BranchParamData.taxID" (selectedChange)="editBranchParam(BranchParamData)">
                    <nb-option *ngFor="let tax of taxes" [value]="tax.id">{{ tax.name }}</nb-option>
                  </nb-select>
                </div>
              </div>
              <div class="padding"></div>
              <div class="row">
                <div class="col col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-xs-12">
                  TRN
                  <input class="input-style" type="number" fullWidth nbInput placeholder="{{ branchParams?.trn }}"
                    [(ngModel)]="BranchParamData.trn" [value]="trn" placeholder="trn"
                    (change)="editBranchParam(BranchParamData)" />
                </div>
              </div>
              <div class="padding"></div>
              <div class="row">
                <button nbButton status="success" small (click)="SaveEditBasicInfo()" class="save">
                  <nb-icon icon="save"></nb-icon>
                  Save
                </button>
              </div>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
    <!--Tax-->

    <div class="padding"></div>
    <!--SMS Notification-->
    <nb-accordion>
      <nb-accordion-item #item [expanded]="smsAllowed" [disabled]="!branchParams?.smsAllowed && !smsAllowed">
        <nb-accordion-item-header>
          <div class="container">
            <div class="row">
              <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8">
                SMS Notification
              </div>
              <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <nb-toggle [checked]="smsAllowed" [(ngModel)]="BranchParamData.smsAllowed" (change)="
                    editBranchParam(BranchParamData); SaveEditBasicInfo()
                  ">
                </nb-toggle>
              </div>
            </div>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class="row sms-wrapper">
            <div class="col mb-3">
              SMS Balance
              <input class="input-style" type="number" nbInput [value]="smsBalance" disabled />

              <button status="success" nbButton hero class="btn" (click)="addSms()">
                <nb-icon icon="plus-square-outline"></nb-icon> Add SMS
              </button>
            </div>
          </div>
          <div class="padding"></div>
          <div class="row" *ngIf="smsNotifications == null">
            <strong style="text-align: center">No smsNotifications Found!</strong>
          </div>
          <div class="row">
            <div class="container">
              <div class="table-responsive" *ngIf="smsNotifications != null">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Event</th>
                      <th scope="col">Text</th>
                      <th scope="col">Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let sms of smsNotifications; let i = index">
                      <th scope="row">{{ i + 1 }}</th>
                      <td>Transaction</td>
                      <td>{{ sms.text }}</td>
                      <td>
                        <div class="row d-flex">
                          <div class="col d-flex">
                            <button nbButton hero placement="left" ngbTooltip="Edit Sms" tooltipClass="custom-tooltip"
                              (click)="
                                EditSms(
                                  sms.notificationBaseID,
                                  sms.text,
                                  sms.notificationEventId
                                )
                              " shape="round" size="small" status="primary">
                              <nb-icon icon="edit-outline"></nb-icon>
                            </button>
                            <button nbButton hero placement="top" ngbTooltip="Delete Sms" tooltipClass="custom-tooltip"
                              (click)="deleteSms(sms.notificationBaseID)" shape="round" size="small" status="warning">
                              <nb-icon icon="trash-outline"></nb-icon>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
    <!--SMS Notification-->
    <div class="padding"></div>

    <!--Cashiers-->
    <nb-accordion>
      <nb-accordion-item>
        <nb-accordion-item-header>
          <div class="container">
            <div class="row">
              <div class="col">Cashiers</div>
            </div>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class="row">
            <div class="col-12">
              <button status="success" nbButton hero class="btn" (click)="addCashier()">
                <nb-icon icon="plus-square-outline"></nb-icon>
                Add Cashier
              </button>
            </div>
          </div>
          <div class="padding"></div>

          <div class="row" *ngIf="cashiers == null">
            <strong style="text-align: center">No Cashiers Found!</strong>
          </div>
          <div class="row">
            <div class="container">
              <div class="table-responsive" *ngIf="cashiers != null">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let cashier of cashiers; let i = index">
                      <th scope="row">{{ i + 1 }}</th>
                      <td>{{ cashier.userName }}</td>
                      <td>{{ cashier?.userType || " " }}</td>
                      <td>
                        <div class="row">
                          <div class="col d-flex">
                            <button nbButton hero placement="left" ngbTooltip="Reset Cashier Password"
                              tooltipClass="custom-tooltip" (click)="
                                resetCashierPassword(
                                  cashier.userId,
                                  cashier.userName,
                                  cashier.userPassword
                                )
                              " shape="round" size="small" status="success">
                              <nb-icon icon="refresh-outline"></nb-icon>
                            </button>
                            <button nbButton hero placement="top" ngbTooltip="Edit Cashier"
                              tooltipClass="custom-tooltip" (click)="
                                editCashier(
                                  cashier.userId,
                                  cashier.userName,
                                  cashier.userType,
                                  cashier.userPassword,
                                  cashier.posRoleId
                                )
                              " shape="round" size="small" status="primary">
                              <nb-icon icon="edit-outline"></nb-icon>
                            </button>
                            <button nbButton hero placement="left" ngbTooltip="Delete Cashier"
                              tooltipClass="custom-tooltip" (click)="deleteCashier(cashier.userId)" shape="round"
                              size="small" status="warning">
                              <nb-icon icon="trash-outline"></nb-icon>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
    <!--Cashiers-->
    <div class="padding"></div>
    <!--Users-->
    <nb-accordion>
      <nb-accordion-item>
        <nb-accordion-item-header>
          <div class="container">
            <div class="row">
              <div class="col">Users</div>
            </div>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class="row">
            <div class="col-12">
              <button status="success" nbButton hero class="btn" (click)="addUser(branchId)">
                <nb-icon icon="plus-square-outline"></nb-icon>
                Add User
              </button>
            </div>
          </div>
          <div class="padding"></div>
          <strong *ngIf="users == null" style="text-align: center">No Users Found!</strong>
          <div class="table-responsive" *ngIf="users != null">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Name</th>
                  <th scope="col">Role</th>
                  <th scope="col">Options</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of users; let i = index">
                  <th scope="row">{{ i + 1 }}</th>
                  <td>{{ user.userName }}</td>
                  <td>{{ user.role }}</td>
                  <td>
                    <div class="row">
                      <div class="col d-flex">
                        <button nbButton placement="left" ngbTooltip="Reset User Password" tooltipClass="custom-tooltip"
                          (click)="
                                resetUserPassword(
                                  branchId,
                                  user.userName,
                                  user.id,
                                  user.email,
                                  user.password,
                                  user.confirmpassword
                                )
                              " shape="round" size="small" status="success">
                          <nb-icon icon="refresh-outline"></nb-icon>
                        </button>
                        <button nbButton hero placement="left" ngbTooltip="Edit User" tooltipClass="custom-tooltip"
                          (click)="
                                editUser(
                                  branchId,
                                  user.id,
                                  user.userName,
                                  user.email,
                                  user.role,
                                  user.password,
                                  user.confirmpassword
                                )" shape="round" size="small" status="primary">
                          <nb-icon icon="edit-outline"></nb-icon>
                        </button>
                        <button nbButton placement="left" ngbTooltip="Delete User" tooltipClass="custom-tooltip"
                          (click)="deleteUser(user.id)" shape="round" size="small" status="warning">
                          <nb-icon icon="trash-outline"></nb-icon>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
    <!--Users-->
    <div class="padding"></div>
    <!--Extra Params-->
    <nb-accordion>
      <nb-accordion-item>
        <nb-accordion-item-header>
          <div class="container">
            <div class="row">
              <div class="col">Extra Params</div>
            </div>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <!-- <div class="row">
            <div class="container">
              <div class="col-12" *ngFor="let extraparam of ExtraParams; let i = index">
                <nb-toggle class="togglerr" [checked]="extraparam.parameterDataType"
                  [(ngModel)]="extraparam.parameterDataType" (change)="editextraParamss(extraparam)">
                </nb-toggle>
                <div class="padding"></div>
                <div class="space">
                  {{ extraparam.paramName }}
                  <input class="input-style" type="text" *ngIf="extraparam.parameterDataType == 'True'" nbInput
                    (change)="editextraParamss(extraparam)" placeholder="Enter Param Value"
                    [(ngModel)]="extraparam.value" [value]="extraparam.value" />
                </div>
              </div>
              <div class="padding"></div>
              <div class="row" *ngIf="branchData?.branchOptionalParameters.length != 0">
                <button nbButton status="success" small (click)="SaveEditExtraParams()" class="save">
                  <nb-icon icon="save"></nb-icon>
                  Save
                </button>
              </div>
            </div>
          </div> -->
          <app-dynamic-form [model]="ExtraParams" (onChange)="onExtraParamsFormChange($event)"></app-dynamic-form>
          <button nbButton status="success" small (click)="SaveEditExtraParams()" class="save">
            <nb-icon icon="save"></nb-icon>
            Save
          </button>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
    <!--Extra Params-->
    <div class="padding"></div>
    <nb-accordion>
      <nb-accordion-item>
        <nb-accordion-item-header>
          <div class="container">
            <div class="row">
              <div class="col">Workers</div>
            </div>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <app-workers></app-workers>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
  </div>
  <div class="col col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
    <app-pos-info></app-pos-info>
    <app-branch-logs [branchID]="branchId"></app-branch-logs>
    <!-- <app-branch-icons [branchId]="branchId"></app-branch-icons> -->
    <app-branch-logos [branchId]="branchId"></app-branch-logos>
  </div>
</div>
