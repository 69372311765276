import { Component, OnInit } from '@angular/core';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { NbToastrService } from '@nebular/theme';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BranchService } from '../../services/branch.service';
import { CustomersService } from '../../services/customers.service';


@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {
  loading = false;
  customers: any = [];
  businessId: string = "";
  constructor(private branchService: BranchService, 
    public modalService: NgbModal, 
    private nbToastr: NbToastrService, 
    private customersService: CustomersService) { }

  ngOnInit(): void {
    this.getBrnachBusinessId();
  }

  onAddCustomer() {
    const modalRef = this.modalService.open(AddCustomerComponent, { centered: true });
    modalRef.componentInstance.businessId = this.businessId;
    modalRef.closed.subscribe(res => {
      if (res == 'success') {
        this.getCustomers();
        this.nbToastr.show('cutomer created successfully', 'success', {
          status: 'success',
          hasIcon: false,
          icon: 'outline-checkmark'
        });
      }
    })
  }

  onEdit(customer: any) {
    const modalRef = this.modalService.open(AddCustomerComponent, { centered: true });
    modalRef.componentInstance.customer = customer;
    modalRef.closed.subscribe(res => {
      if (res == 'success') {
        this.getCustomers();
        this.nbToastr.show(res.message, 'success', {
          status: 'success',
          hasIcon: false,
          icon: 'outline-checkmark'
        });
      }
    })
  }

  onDelete(id: number) {
    this.customersService.deleteCustomer(id).subscribe(res => {
      this.nbToastr.show('customer Deleted', 'success', {
        status: 'success',
        hasIcon: false,
        icon: 'outline-checkmark'
      });

      this.getCustomers();
    }, err => {
      this.nbToastr.show(err.error.Message, 'Error', {
        status: 'danger',
        hasIcon: false,
        icon: 'close-outline'
      });
    })
  }

  getCustomers() {
    this.customersService.getCustomers(this.businessId).subscribe(res => {
      console.log(res)
      this.customers = res;
    })
  }

  getBrnachBusinessId() {
    if(JSON.parse(localStorage.getItem('searchVal')!).type == "branch") {
      let branchId = JSON.parse(localStorage.getItem('searchVal')!).id;
      this.branchService.getBusinessByBranchId(branchId).subscribe((res: any)=> {
        this.businessId = res.branch.businessId;
        this.getCustomers();
      })
    } else {
      this.businessId = JSON.parse(localStorage.getItem('searchVal')!).businessId;
      this.getCustomers();
    }
  }
}
