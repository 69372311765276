<div class="card-row text-center">
  <div class="card-col">
    <nb-card size="small">
      <nb-card-body>
        <nb-layout>
          <nb-layout-column class="colored-column-warning p-0">
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <img
                [src]="baseImageUrl + info.imageId"
                alt=""
                class="my-3"
                *ngFor="let info of posInfo"
              />
            </div>
          </nb-layout-column>
          <nb-layout-column class="colored-column-danger p-0">
            <nb-tag-list *ngFor="let info of posInfo" class="my-3">
              <nb-tag
                status="warning"
                appearance="outline"
                [text]="'Type: ' + info!.posType"
              >
              </nb-tag>
              <nb-tag
                status="warning"
                appearance="outline"
                [text]="
                  posInfo.length > 1
                    ? 'Count: ' + info.count
                    : 'Status: ' + info!.posStatus
                "
              ></nb-tag>
              <nb-tag
                status="warning"
                appearance="outline"
                [text]="
                  posInfo.length > 1
                    ? 'Online: ' + info.online
                    : 'Terminal ID: ' + info!.terminalId
                "
              ></nb-tag>

              <nb-tag
                appearance="outline"
                status="warning"
                [text]="
                  posInfo.length > 1
                    ? 'Offline: ' + info.offline
                    : 'S/N: ' + info!.sn
                "
              ></nb-tag>
            </nb-tag-list>
          </nb-layout-column>
        </nb-layout>
      </nb-card-body>
      <button nbButton status="primary" class="complete-recall mt-2">
        Comblete Recall
        <nb-icon icon="refresh-outline"></nb-icon>
      </button>
      <br />
    </nb-card>
  </div>

  <!-- <nb-button-group status="warning" ghost style="margin-bottom: 12px">
    <button nbButton >
      <nb-icon icon="grid-outline" class="pos-ico"></nb-icon>
    </button>
    <button nbButton >
      <nb-icon icon="copy-outline" class="pos-ico"></nb-icon>
    </button>
    <button nbButton>
      <nb-icon icon="undo-outline" class="pos-ico"></nb-icon>
    </button>
  </nb-button-group> -->
</div>
