import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BuisnessService } from '../../../services/buisness.service';
import { CustomersService } from '../../../services/customers.service';


@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit {
  @Input() businessId: any;
  @Input() customer: any;

  addCustomerForm: FormGroup = new FormGroup({
    businessId: new FormControl(''),
    fullname: new FormControl('', Validators.required),
    phone: new FormControl('', [Validators.required]),
    email: new FormControl(''),
    gender: new FormControl(0, Validators.required),
    customerType: new FormControl(1, Validators.required),
    address: new FormGroup({
      street: new FormControl(''),
      cityId: new FormControl('', Validators.required),
      location: new FormControl(''),
      district: new FormControl(''),
      flat: new FormControl(''),
      buildingName: new FormControl(''),
      completeAddress: new FormControl('')
    })
  });
  // Validators.pattern(/^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/)]
  cities: any[] = [];
  countries: any[] = [];
  activeModal!: NgbActiveModal;
  sumbitted = false;
  selectedCountry: any;
  selectedCity: any;
  constructor(
    private injector: Injector, 
    private businessService: BuisnessService, 
    public modalService: NgbModal,
     private nbToastr: NbToastrService,
      private customersService: CustomersService) {
    this.activeModal = this.injector.get<NgbActiveModal>(NgbActiveModal);
  }

  ngOnInit(): void {
    this.onGetCountries();
    //console.log(this.businessId)
    if (this.customer) {
      console.log(this.customer)
      this.addCustomerForm.patchValue({
        fullname: this.customer.fullname,
        phone: this.customer.phoneNumber,
        email: this.customer.email,
        customerType: this.customer.customerType.id,
        address: {
          street: this.customer.defaultAddress.street ? this.customer.defaultAddress.street : "",
          cityId: this.customer.defaultAddress.city? this.customer.defaultAddress.city.id : "",
          location: this.customer.defaultAddress?.location? this.customer.defaultAddress.location : "",
          district: this.customer.defaultAddress.district?this.customer.defaultAddress.district : "",
          flat: this.customer.defaultAddress.flat? this.customer.defaultAddress.flat: "",
          buildingName: this.customer.defaultAddress.buildingName? this.customer.defaultAddress.buildingName: "",
          completeAddress: this.customer.defaultAddress.fullAddress
        }
      });

      //this.addCustomerForm.addControl('customerId', new FormControl(this.customer.id));
      // this.addCustomerForm.get('address')?.addControl('addressId', new FormControl(this.customer.defaultAddress.addressId));
    }
  }

  onSave() {

    this.sumbitted = true;
    if (this.addCustomerForm.valid) {
      if (this.customer && this.customer.id) {
        console.log(this.addCustomerForm.value);
        this.addCustomerForm.get('businessId')?.setValue(this.businessId);
        let body = this.addCustomerForm.value;
        body.address.addressId = this.customer.defaultAddress.addressId;
        body.customerId = this.customer.id;
        this.customersService.editCustomer(body).subscribe(res => {
          //console.log(res)
          this.activeModal.close('success');
        }, err => {
          console.log({err});
          this.nbToastr.show(err.error.message, 'Error', {
            status: 'danger',
            hasIcon: false
          });
        });
      } else {
       // console.log(this.addCustomerForm.value)
        this.addCustomerForm.get('businessId')?.setValue(this.businessId);
        this.customersService.addCustomer(this.addCustomerForm.value).subscribe(res => {
          //console.log(res)
          this.activeModal.close('success');
        }, err => {
          console.log(err.error.errors);
          if(err.error.statusCode == 400) {
            let errormsg = err.error.title? err.error.title : err.error.errors[0];
            this.nbToastr.show(errormsg, 'Error', {
              status: 'danger',
              hasIcon: false
            });
          } else {
            this.nbToastr.show(err.error.message, 'Error', {
              status: 'danger',
              hasIcon: false
            });
          }
        });
      }

      this.sumbitted = false;
    }

  }

  onCancel() {
    this.activeModal.close();
  }

  onGetCountries() {
    this.businessService.getCountries().subscribe(res => {
      this.countries = res.countries;
      if (this.customer) {
        this.selectedCountry = this.countries.find(country => {
          return country.id == this.customer.defaultAddress.city.countryId
        });

        this.onGetCities(this.selectedCountry);
      }
    }, err => console.log(err))
  }

  onGetCities(country: any) {
    //console.log(country)
    this.businessService.getCitiesByCountry(country.id).subscribe(res => {
      this.cities = res.cities;

      if (this.customer) {
        this.selectedCity = this.cities.find(city => {
          return city.id == this.customer.defaultAddress.city.id
        }).id;
      }
    }, err => console.log(err))
  }
}
