import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PrintingLayout, PrintingLayoutKey } from '../../../models/printing-layout.model';
import { BranchService } from '../../../services/branch.service';


@Component({
  selector: 'app-view-layout',
  templateUrl: './view-layout.component.html',
  styleUrls: ['./view-layout.component.scss'],
})
export class ViewLayoutComponent implements OnInit, OnChanges {
  @Input() layout!: PrintingLayout;
  @Input() branchId!: string;
  layoutText!: string;
  addedKeys: PrintingLayoutKey[] = [];
  sortedKeys: any[] = [];
  // imgurlPlaceholder : string = '<img src="https://www.altkamul.net/content/merchants/50112101/50112101/printing.bmp" width="200">'
  imgurlPlaceholder : string = 'https://www.altkamul.net/content/merchants/50112101/50112101/printing.bmp';
  constructor(
    private branchService: BranchService
  ) {}

  ngOnInit(): void {
    console.log(this.branchId);
    
    if (this.branchId) {
      this.onGetBranchPrintLogo();
      // this.imgurlPlaceholder = '';

    }
    console.log(this.layout)
    // console.log(this.layout.layoutText)
    const layoutJson = JSON.parse(this.layout.layoutText);
    console.log(layoutJson);

    this.sortedKeys = layoutJson.sort;
    this.addedKeys = this.sortedKeys.map((el: string) => {
      return layoutJson[el];
    });
    // let logoIndex = this.addedKeys.findIndex(k => k.name === 'logo');

    // if (logoIndex !== -1) {
    //     let logoKey = { ...this.addedKeys[logoIndex] };
    //     logoKey.initialValue = logoKey.initialValue.replace(
    //       /(<img.*?src=")(.*?)(")/,  
    //       `$1${this.imgurlPlaceholder}$3`            
    //     );
    //     this.addedKeys[logoIndex] = logoKey;
        
    // }
    
  

    // this.layoutText = this.parseHTML(
      // layoutJson.sort? layoutJson.sort : layoutJson.sortedlist,
      // layoutJson
    // );

    console.log(this.layoutText)
  }
  onGetBranchPrintLogo() {
    this.branchService.getBranchPrintLogo(this.branchId).subscribe(res => {
      // console.log('printing logo',res);
      (res)? this.imgurlPlaceholder = res : ''
       
    }, err => console.log(err))
  }
  getLayoutPreview(): string {
    // console.log('getLayoutPreview');

    return this.addedKeys.reduce(
      (acc, curr) => acc + this.renderKeysOptions2(curr) + '','');
      // (acc, curr) => acc + this.renderKeysOptions2(curr) + '<br>','');
  }
  renderKeysOptions2(key: PrintingLayoutKey): string {
    // console.log('renderKeysOptions');

    let para = "<p class='";
    let value = key.label ? key.initialValue : key.initialValue;

    if (key.name=='logo') {
      key.initialValue = `<img src="${this.imgurlPlaceholder}" width="200">`;
      key.options!.align=1;
    }
    if (key.options!.size == 2) {
      para += 'fw-bold ';
    }

    if (key.options!.align == 0) {
      para += 'text-left ';
    }

    if (key.options!.align == 1) {
      para += 'text-center ';
    }

    if (key.options!.align == 2) {
      para += 'text-end ';
    }

    para += "'>" + value + '</p>';
    return para;
  }
  ngOnChanges(changes: any) { //changes: SimpleChanges
    // const layoutJson = JSON.parse(changes.layout.currentValue.layoutText);
    // //console.log(layoutJson)
    // this.layoutText = this.parseHTML(
    //   layoutJson.sort? layoutJson.sort : layoutJson.sortedlist,
    //   layoutJson
    // );
  }



  renderKeysOptions(key: any): string {
    console.log({key})
    let para = "<p class='mb-1";
    let value = key.initValue ? key.initValue : key.initialValue;
    
    let classes = ' class="mb-1"';

    if(value.search('img') !== -1) {
      let index = value.search('img');
      console.log({index})
      value = [value.slice(0, index + 3), ' class="img-fluid"', value.slice(index + 3)].join('');
    }

    value = [value.slice(0, 2), classes, value.slice(2)].join('');
    // console.log(value)

    if (key.size == 2) {
      para += 'fw-bold ';
    }

    if (key.align == 0) {
      para += 'text-left ';
    }

    if (key.align == 1) {
      para += 'text-center ';
    }

    if (key.align == 2) {
      para += 'text-end ';
    }

    para += "'>" + value + '</p>';
    //console.log(para)
    return value;
  }

  parseHTML(list: any[], layout: any) {
    //TODO: reomve layout param if not needed
    //console.log({list}, {layout})
    let html = '';
    list.forEach((element) => {
      let value = this.renderKeysOptions(element);
      html += value;
    });

    return html;
  }
}
